
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters({
			settings: 'settings/settings/settings'
		}),
		socials() {
			return [
				{
					link: `${this.settings.soc_telegram}`,
					type: 'telegram',
				},
				{
					link: `${this.settings.soc_instagram}`,
					type: 'instagram',
				},
				{
					link: `${this.settings.soc_vk}`,
					type: 'vk',
				},
				{
					link: `${this.settings.soc_youtube}`,
					type: 'youtube',
				},
			]
		}
	}
}
