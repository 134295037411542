
import {mapActions, mapGetters} from "vuex";

export default {
	computed: {
		...mapGetters({
			benefit: 'benefits/benefit'
		})
	},
	methods: {
		...mapActions({
			closeBenefit: 'benefits/closeBenefit'
		})
	}
}
